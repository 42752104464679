import React, { Component } from 'react';

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        subject: 'Enquiry',
        lastname: '',
        notes: '',
        error: {},
        successMessage: '',
        errorMessage: ''
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error,
            successMessage: '',
            errorMessage: ''
        });
    }

    submitHandler = (e) => {
        e.preventDefault();
    
        const { name, email, subject, lastname, notes, error } = this.state;
    
        // Validate the form fields
        if (name === '') error.name = "Please enter your name";
        if (email === '') error.email = "Please enter your email";
        if (subject === '') error.subject = "Please enter your subject";
        if (lastname === '') error.lastname = "Please enter your Lastname";
        if (notes === '') error.notes = "Please enter your note";
    
        // If no errors, proceed with sending the email
        if (Object.values(error).every(err => err === '')) {
            fetch("https://divinecreators.co.za/api/api/send-enquiry", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ name, email, subject, message: notes }),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Failed to send email.");
                }
                return response.json();
              })
              .then((data) => {
                if (data.error) {
                  this.setState({ errorMessage: "Failed to send email." });
                } else {
                  this.setState({
                    successMessage: "Email sent successfully!",
                    name: "",
                    email: "",
                    subject: "Enquiry",
                    lastname: "",
                    notes: "",
                    error: {},
                  });
                }
              })
              .catch(() =>
                this.setState({
                  errorMessage: "Failed to send email.",
                })
              );
        } else {
            this.setState({ error });
        }
    }
    
    render() {
        const { name, email, subject, lastname, error, successMessage, errorMessage } = this.state;

        return (
            <div>
                <form onSubmit={this.submitHandler} className="form">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-field">
                                <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name" />
                                <p>{error.name ? error.name : ''}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="form-field">
                                <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname" />
                                <p>{error.lastname ? error.lastname : ''}</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-field">
                                <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email" />
                                <p>{error.email ? error.email : ''}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-field">
                                <textarea name="notes" placeholder="Message" value={this.state.notes} onChange={this.changeHandler}></textarea>
                                <p>{error.notes ? error.notes : ''}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-submit">
                                <button type="submit" className="theme-btn">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
                {successMessage && <p className="success">{successMessage}</p>}
                {errorMessage && <p className="error">{errorMessage}</p>}
            </div>
        );
    }
}

export default ContactForm;