import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.png'
import ts2 from '../../images/testimonial/img-2.png'
import ts3 from '../../images/testimonial/img-3.png'


class Testimonial extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonial = [
            {
                tsImg: ts1,
                Des: "The exponential growth of online studies through Learning Management Systems (LMS) has revolutionized the way I learn. The accessibility and flexibility of LMS platforms have been a game-changer, allowing me to learn at my own pace and on my own schedule.",
                Title: 'Rachel dutoit',
                Sub: "Student",
            },
            {
                tsImg: ts2,
                Des: "I can access course materials, participate in discussions, and submit assignments from anywhere with an internet connection. The interactive features, such as quizzes, forums, and video lectures, have made learning more engaging and effective.",
                Title: 'Thapelo Mahlangu',
                Sub: "Student",
            },
            {
                tsImg: ts3,
                Des: "The LMS has provided me with valuable tools for organization and time management, helping me stay on track with my studies. Overall, the LMS has been an invaluable resource for my academic success, and I highly recommend it to any student seeking a dynamic and efficient learning experience.",
                Title: 'Nhlanhla Thobejane',
                Sub: "Student",
            }
        ]
        return (
          <div className="wpo-testimonial-area section-padding">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="wpo-section-title">
                    <span>Testimonial</span>
                    <h2>What People Say About Us</h2>
                    <p>
                      We have serviced numerous individuals, showcasing diverse
                      variations in their needs and experiences across a wide
                      range of passages.
                    </p>
                  </div>
                </div>
              </div>
              <div className="wpo-testimonial-wrap">
                <div className="testimonial-slider owl-carousel">
                  <Slider {...settings}>
                    {testimonial.map((tesmnl, tsm) => (
                      <div className="wpo-testimonial-item" key={tsm}>
                        <div className="wpo-testimonial-img">
                          <img src={tesmnl.tsImg} alt="" />
                        </div>
                        <div className="wpo-testimonial-content">
                          <p>{tesmnl.Des}</p>
                          <h2>{tesmnl.Title}</h2>
                          <span>{tesmnl.Sub}</span>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default Testimonial;