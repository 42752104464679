import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo2.png'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={Logo} alt="blog" style={{ width: "178px", height: "55px" }}/>
                            </div>
                            <p>Empowering individuals and organizations through skills training.</p>
                            <ul>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/divinecreatorsacademy/">
                                        <i className="ti-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/divine-creators-pty">
                                        <i className="ti-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/_divinecreators/">
                                        <i className="ti-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@DivineCreators-2024">
                                        <i className="ti-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Services </h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/mission-vission/1">Mission & Vission</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <p>Would you have any enquiries. Please feel free to contact us</p>
                                <ul>
                                    <li><i className="fi flaticon-mail"></i>info@divinetraining.co.za</li>
                                    <li><i className="fi flaticon-phone-call"></i>+27 (76) 313 5975</li>
                                    <li><i className="fi flaticon-location"></i>Springs - 47 Eleventh Street, Springs CBS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> &copy;2024 Divine Training. All right reserved by Divine Creators. </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;