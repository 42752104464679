import React from "react";


import hero1 from '../../images/slider/right-img2.png'
import hero3 from '../../images/slider/right-img.png'


const Hero =() => {
    return (
      <section className="wpo-hero-section-1">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col col-xs-6 col-lg-6">
              <div className="wpo-hero-section-text">
                <div className="wpo-hero-title-top">
                  <span>Divine Creators</span>
                </div>
                <div className="wpo-hero-title">
                  <h2>Your Success Matter! </h2>
                </div>
                <div className="wpo-hero-subtitle">
                  <p>
                    “Education shouldn’t cost you economic freedom and success”.
                  </p>
                  <p>
                    It’s time to change and transform your original intent
                    skills.
                  </p>
                  <p>
                    Unlock economic freedom and success through equipping and
                    building individuals.
                  </p>
                </div>
                <div className="btns">
                  <a
                    href="https://online.fliphtml5.com/avjzc/mjqp/"
                    className="btn theme-btn-s2"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-block",
                      position: "relative",
                      padding: "10px 20px",
                      textDecoration: "none",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      Click Prospectus
                    </span>
                    <i
                      className="fi flaticon-next"
                      style={{
                        marginLeft: "10px",
                        verticalAlign: "middle",
                        position: "relative",
                        top: "3px",
                      }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-vec">
                <div className="right-items-wrap">
                  <div className="right-item">
                    <div className="r-img">
                      <img src={hero1} alt="" />
                    </div>
                  </div>
                  <div className="right-item">
                    <div className="r-img">
                      <img src={hero3} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}



export default Hero;
