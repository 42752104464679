import React from 'react'
import {Link} from  'react-router-dom'
import abimg from '../../images/about.png'
import shape from '../../images/ab-shape-2.png'


const About = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                                <div className="wpo-ab-shape-1">
                                    <div className="s-s1"></div>
                                    <div className="s-s2"></div>
                                </div>
                                <div className="wpo-ab-shape-2"><img src={shape} alt=""/></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>About Us</span>
                            <h2>Empowering individuals and organizations through skills training.</h2>
                            <p>Our purpose is to empower individuals with skills that boost employability and career opportunities. For organizations, skills training is crucial for competitiveness, productivity, and workforce motivation. Our approach combines learning and performance goals, helping individuals build and apply their skills in real-world scenarios. This approach fosters growth, engagement, and a stronger economy.</p>
                            <ul>
                                <li>Divine Creators skills training equips individuals with the necessary knowledge, abilities, and competencies to succeed in their chosen field.</li>
                                <li>By participating in such training, individuals can enhance their employability, increase their earning potential, and gain a competitive edge in the job market.</li>
                                <li>Our skills training fosters personal growth and development, enabling individuals to acquire new skills, expand their knowledge base, and broaden their horizons.</li>
                                <li>Our training leads to increase confidence, self-esteem, and a sense of accomplishment.</li>
                                <li>Divine Creators skills training can contribute to societal progress by equipping individuals with the skills needed to address critical challenges and contribute to the overall well-being of society. Therefore, participating in our skills training is essential for personal and professional growth, as well as for contributing to a more prosperous and equitable society.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;