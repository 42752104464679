import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

const EventTabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="wpo-event-details-wrap">
            <div className="wpo-event-details-tab">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Our Mission
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Our Vision
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            More About Us
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>

            <div className="wpo-event-details-content">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <div id="Mission" className="tab-pane active">
                                    <h3>Our Mission</h3>
                                    <p>
                                        Our mission is to <strong>empower unskilled individuals</strong> with the knowledge, skills, and resources they need to:
                                    </p>
                                    <ul>
                                        <li>Achieve economic independence</li>
                                        <li>Build a brighter future</li>
                                        <li>Contribute meaningfully to society</li>
                                    </ul>
                                    <p><strong>Your success is our mission!</strong></p>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <div id="Vision" className="tab-pane active">
                                    <h3>Our Vision</h3>
                                    <p>
                                        We aim to <strong>empower individuals</strong> through transformative skills training that focuses on:
                                    </p>
                                    <ul>
                                        <li>Fostering personal growth</li>
                                        <li>Enhancing career opportunities</li>
                                        <li>Cultivating a culture of lifelong learning</li>
                                    </ul>
                                    <p>
                                        Our dynamic learning environment supports diverse talents, helping participants:
                                    </p>
                                    <ul>
                                        <li>Unlock their full potential</li>
                                        <li>Contribute meaningfully to their communities and industries</li>
                                    </ul>
                                    <p>
                                        Through <strong>innovative teaching methods</strong> and <strong>real-world applications</strong>, we equip learners with the essential skills needed to navigate an ever-evolving job market.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <div id="MoreAboutUs" className="tab-pane active">
                                    <h3>More About Us</h3>
                                    <p>
                                        Divine Creators offers courses designed to equip individuals with the necessary skills and knowledge to excel in the workplace. We focus on:
                                    </p>
                                    <ul>
                                        <li>Providing valid and reliable training</li>
                                        <li>Meeting the needs of both learners and employers</li>
                                        <li>Contributing to workforce development and skill enhancement</li>
                                    </ul>
                                    <p>In just a short time, we have successfully trained <strong>2133 learners</strong>.</p>

                                    <h4>Empowering Unskilled Individuals</h4>
                                    <p>We empower unskilled individuals by equipping them with practical abilities directly applicable to the job market, focusing on:</p>
                                    <ul>
                                        <li>Technical skills</li>
                                        <li>Communication</li>
                                        <li>Problem-solving</li>
                                    </ul>
                                    <br/>

                                    <h4>Fostering Confidence and Self-Efficacy</h4>
                                    <p>
                                        As participants gain new skills, they become more confident and better prepared to take on responsibilities, leading to:
                                    </p>
                                    <ul>
                                        <li>Improved job performance</li>
                                        <li>Greater opportunities for advancement</li>
                                    </ul>

                                    <br/>
                                    <h4>Bridging the Gap Between Education and Employment</h4>
                                    <p>
                                        Our skills training programs help unskilled individuals enhance their resumes and demonstrate to potential employers that they possess the necessary skills for the job. This contributes to:
                                    </p>
                                    <ul>
                                        <li>Increased employability</li>
                                        <li>A more skilled workforce overall</li>
                                    </ul>

                                    <br/>
                                    <h4>Key Training Areas</h4>
                                    <p>We offer accredited skills training in:</p>
                                    <ul>
                                        <li>Business Administration</li>
                                        <li>New Venture Creation</li>
                                    </ul>
                                    <p>
                                        These programs are vital for unskilled individuals as they provide practical skills, boost confidence, and enhance employability, contributing to both personal growth and economic development.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
}

export default EventTabs;
