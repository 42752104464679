import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import { useParams } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import Events from '../../api/event';
import EventTabs from './alltab';
import Logo from '../../images/logo.png';
import Footer from '../../components/footer';

const EventSinglePage = (props) => {
    const { id } = useParams();

    const eventDetails = Events.find(item => item.id === id);

    return (
        <Fragment>
            <Navbar Logo={Logo} />
            <PageTitle pageTitle={eventDetails.eTitle} pagesub={'Mission & Vission'} />
            <div className="wpo-event-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="wpo-event-item">
                                {/* Uncomment this if you want to display the event image */}
                                {/* <div className="wpo-event-img">
                                    <img src={eventDetails.eImg} alt={eventDetails.eTitle} />
                                </div> */}
                                <div className="wpo-event-details-text">
                                    <h2>About {eventDetails.eTitle}</h2>
                                    <p>
                                        At Divine Creators, we are dedicated to empowering individuals through comprehensive skills training that prepares them for success in today’s competitive job market. Our event, <strong>{eventDetails.eTitle}</strong>, is designed to provide participants with practical skills, knowledge, and the confidence needed to excel in their chosen fields.
                                    </p>
                                    <p>
                                        Join us as we explore the essential skills and strategies that drive personal and professional growth. Whether you are an unskilled individual looking to enter the workforce or someone seeking to enhance your current skill set, this event offers valuable insights and hands-on training.
                                    </p>
                                </div>
                                <EventTabs />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default EventSinglePage;
