import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import Testimonial from '../../components/Testimonial'
import CtaSection from '../../components/ctaSection'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import PartnerSection from '../../components/PartnerSection';
import Service from '../../components/Service'
import Service5 from '../../components/Service5'
import vimg from '../../images/volunteer.png'


const HomePage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <Hero/>
            <Service5/>
            <Testimonial/>
            <CtaSection vImg={vimg} cTitle={'Click "Enroll Now" to enroll with us!'}/>
            <PartnerSection/>
            <Scrollbar/>
            <Footer/>
        </Fragment>
    )
};
export default HomePage;